/* Bourbon */
.top-header .user-pref#currency .currency-cont li a:hover {
  color: #1ca7e0;
}

#menu nav ul li a:hover, #menu nav ul li a.active, .top-header .user-pref#search:hover {
  background-color: #1ca7e0;
  color: #ffffff;
}

/*@include font-face('helvetica_ce_35_thinregular', "../../fonts/webfonts/helvetica-webfont");
@include font-face('montserratregular', "../../fonts/webfonts/montserrat-bold-webfont");
@include font-face('montserratbold', "../../fonts/webfonts/montserrat-regular-webfont");*/
/* Bourbon */
.clearfix,
.group {
  zoom: 1;
}

.clearfix:before, .clearfix:after,
.group:before,
.group:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after,
.group:after {
  clear: both;
}

.buffer-xs-0 {
  margin-top: 0;
}

.buffer-xs-10 {
  margin-top: 10px;
}

.buffer-xs-20 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0;
  }
  .buffer-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0;
  }
  .buffer-md-10 {
    margin-top: 10px;
  }
}

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url("../../img/iCheck/square/blue@2x.png");
  }
}

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none;
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.uno-mfp-popup {
  max-width: 600px;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;
}

.uno-mfp-popup.white {
  background-color: white;
}

.uno-mfp-popup.white .mfp-close {
  color: black;
  font-size: 22px;
}

.uno-mfp-popup.white > .box {
  border-radius: 0;
}

.uno-mfp-popup > .popup-container {
  padding: 20px;
}

.uno-mfp-popup > .popup-container > h4 {
  margin: 0;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  color: #FFF;
}

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 0;
}

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif");
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
}

.slick-arrow:before {
  transition: opacity 0.5s;
}

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999;
}

label.mce-label {
  max-width: none;
}

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important;
}

.mce-panel {
  max-width: 100% !important;
}

.mce-tabs {
  max-width: 100% !important;
}

.mce-container-body {
  max-width: 100% !important;
}

.mce-container {
  max-width: 100% !important;
}

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg");
}

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png");
}

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-overlay-loading span {
  background-image: url("../../assets/mediaelement/loading.gif");
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg");
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png");
}

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png");
}

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png");
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png");
}

/* No bold */
.no-bold {
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img {
  background: no-repeat center;
}

.bg-img.bg-cover {
  background-size: cover;
}

.bg-img.bg-contain {
  background-size: contain;
}

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token {
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block {
  display: block;
}

.btn:focus {
  outline: none;
}

@font-face {
  font-family: Lora-Regular;
  src: url("../../fonts/Lora-Regular.eot?mrksyq");
  src: url("../../fonts/Lora-Regular.eot?mrksyq#iefix") format("embedded-opentype"), url("../../fonts/Lora-Regular.ttf?mrksyq") format("truetype"), url("../../fonts/Lora-Regular.woff?mrksyq") format("woff"), url("../../fonts/Lora-Regular.svg?mrksyq#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Muli-Regular;
  src: url("../../fonts/Muli-Regular.eot?mrksyq");
  src: url("../../fonts/Muli-Regular.eot?mrksyq#iefix") format("embedded-opentype"), url("../../fonts/Muli-Regular.ttf?mrksyq") format("truetype"), url("../../fonts/Muli-Regular.woff?mrksyq") format("woff"), url("../../fonts/Muli-Regular.svg?mrksyq#Muli-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Muli-Bold;
  src: url("../../fonts/Muli-Bold.eot?mrksyq");
  src: url("../../fonts/Muli-Bold.eot?mrksyq#iefix") format("embedded-opentype"), url("../../fonts/Muli-Bold.ttf?mrksyq") format("truetype"), url("../../fonts/Muli-Bold.woff?mrksyq") format("woff"), url("../../fonts/Muli-Bold.svg?mrksyq#Muli-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Muli-Light;
  src: url("../../fonts/Muli-Light.eot?mrksyq");
  src: url("../../fonts/Muli-Light.eot?mrksyq#iefix") format("embedded-opentype"), url("../../fonts/Muli-Light.ttf?mrksyq") format("truetype"), url("../../fonts/Muli-Light.woff?mrksyq") format("woff"), url("../../fonts/Muli-Light.svg?mrksyq#Muli-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MicrosoftYaHei;
  src: url("../../fonts/MicrosoftYaHei.eot?mrksyq");
  src: url("../../fonts/MicrosoftYaHei.eot?mrksyq#iefix") format("embedded-opentype"), url("../../fonts/MicrosoftYaHei.ttf?mrksyq") format("truetype"), url("../../fonts/MicrosoftYaHei.woff?mrksyq") format("woff"), url("../../fonts/MicrosoftYaHei.svg?mrksyq#MicrosoftYaHei") format("svg");
  font-weight: normal;
  font-style: normal;
}

.header-wrapper .header .header-right .header-right-top .header-information .header-title {
  font-family: "MicrosoftYaHei";
  font-size: 30px;
}

.header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item, .home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-description, .home-cms-amenities .home-cms-amenities-subtitle, .home-cms-special .home-cms-special-subtitle {
  font-family: "MicrosoftYaHei";
  font-size: 15px;
}

#banner .descs .desc .link, .header-bottom .header-booking .header-booking-title .booking-subtitle, .header-menu nav ul li, .header-menu nav ul li a, .home-cms-amenities .home-cms-amenities-button a, .home-cms-special .home-cms-special-button a, .home-cms-accommodation .home-cms-accommodation-button a {
  font-family: "MicrosoftYaHei";
  font-size: 13px;
}

#breadcrumb .crumb, .header-bottom .header-booking .header-booking-title .booking-title, .header-booking-form .booking-form, .home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-text .home-cms-special-item-description {
  font-family: "MicrosoftYaHei";
  font-size: 13px;
}

#banner .descs .desc p, #content .page-content h2, .inner-navigation .inner-navigation-main a, .inner-navigation .inner-navigation-list > div.inner-navigation-slick a, .home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-title {
  font-family: "MicrosoftYaHei";
  font-size: 20px;
}

#banner .descs .desc .title {
  font-family: "MicrosoftYaHei";
  font-size: 35px;
}

.header-booking-button .booking-button, .home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns.accommodation-label, #map .googlemap-title, #footer-bottom .footer-bottom-info > div .footer-bottom-socialmedia .social-icon-title, .news_listing_list .news_list .news_item a > div .news_item_content .news_item_date {
  font-family: "MicrosoftYaHei";
  font-size: 14px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-links > div a, .home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-link a, .inner .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-link, #newsletter .newsletter-box .newsletter-icon-btn, #map .googlemap-link a {
  font-family: "MicrosoftYaHei";
  font-size: 11px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-title, .home-cms-amenities .home-cms-amenities-title, .home-cms-special .home-cms-special-title, .home-cms-accommodation .home-cms-accommodation-title, .home-cms-bookdirect .home-cms-bookdirect-title, #footer-top .footer-contactus .footer-contactus-title, #footer-top .footer-newsletter .footer-newsletter-title {
  font-family: "MicrosoftYaHei";
  font-size: 30px;
}

.home-cms-amenities .home-cms-amenities-listing > div .home-cms-amenities-item .home-cms-amenities-name, .home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns.accommodation-desc, #map .googlemap-address, .news_listing_list .news_list .news_item a > div .news_item_text .news_item_description {
  font-family: "MicrosoftYaHei";
  font-size: 14px;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-text .home-cms-special-item-title {
  font-family: "MicrosoftYaHei";
  font-size: 18px;
}

.home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item .home-cms-bookdirect-text, .news_listing_list .news_list .news_item a > div .news_item_text .news_item_name {
  font-family: "MicrosoftYaHei";
  font-size: 15px;
}

#footer-top .footer-contactus .footer-contactus-content .contactus-left .contactus-item .contactus-text, #footer-top .footer-contactus .footer-contactus-content .contactus-right .contactus-item .contactus-text, #footer-bottom .footer-bottom-info > div .footer-bottom-menu ul li, #footer-bottom .footer-bottom-info > div .footer-bottom-menu ul li a {
  font-family: "MicrosoftYaHei";
  font-size: 16px;
}

#footer-bottom .footer-bottom-copyright, #footer-bottom .footer-bottom-copyright a {
  font-family: "MicrosoftYaHei";
  font-size: 11px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-subtitle {
  font-family: "MicrosoftYaHei";
  font-size: 30px;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-title {
  font-family: "MicrosoftYaHei";
  font-size: 26px;
}

body {
  font-size: 12px;
  font-family: "MicrosoftYaHei", "Open Sans", sans-serif;
}

#news-mini .news .slide .date {
  font-size: 12px;
  font-family: "MicrosoftYaHei", "Open Sans", sans-serif;
  font-weight: bold;
}

body {
  line-height: 1.2;
}

blockquote {
  border-left: 2px solid #1271db;
  font-family: "Muli-Regular", Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Muli-Regular", "Open Sans", sans-serif;
  line-height: 1;
  font-weight: bold;
}

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px;
}

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px;
}

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px;
}

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px;
}

p {
  margin: 0 0 20px;
  font-size: 12px;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: #313131;
  transition: all 0.3s linear;
  display: inline-block;
}

a:hover, a:focus {
  text-decoration: none;
  color: #84B736;
}

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

main {
  display: table-row;
}

header {
  display: table-row;
  height: 1px;
}

footer {
  display: table-row;
  height: 1px;
}

.mm-page {
  height: 100%;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height {
  display: table;
  table-layout: fixed;
}

/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 992px) {
  .row-md-same-height {
    display: block;
  }
}

.row-sm-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  .row-sm-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}

.btn {
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s linear;
  color: #84B736;
  background: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px 35px;
  border-radius: 30px;
  font-family: "Muli-Bold";
  font-size: 13px;
}

.btn:hover, .btn:focus {
  background: #84B736;
  border-color: #84B736;
  color: #fff;
  text-decoration: none;
}

.btn:active {
  background: #68902a;
  border-color: #68902a;
}

.btn-primary {
  background-color: #313131;
  border-color: #313131;
  color: #fff;
  transition: all 0.3s linear;
}

.btn-primary:hover, .btn-primary:focus {
  background: #76a330;
  border-color: #76a330;
  color: #fff;
}

.btn-primary:active {
  background: #68902a;
  border-color: #68902a;
  color: #fff;
}

.btn-lg {
  font-size: 1.4em;
  height: 66px;
  padding: 0 60px;
}

.btn-sm {
  font-size: .85em;
  height: 30.8px;
  padding: 0 10px;
}

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #1271db;
  text-shadow: none;
  text-transform: none;
}

.btn-link:hover, .btn-link:focus {
  background: transparent;
  color: #1271db;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 44px;
  outline: none;
  padding: 5px 10px;
  width: 300px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
textarea:focus {
  border-color: #1271db;
}

input[type=text].full-width,
input[type=email].full-width,
input[type=phone].full-width,
input[type=password].full-width,
input[type=number].full-width,
input[type=search].full-width,
textarea.full-width {
  width: 100%;
}

textarea {
  height: 132px;
}

form {
  margin-bottom: 40px;
}

form .field {
  margin-bottom: 20px;
}

form .hint {
  color: #aaa;
  margin-top: 5px;
}

form .is-required {
  font-style: italic;
}

form .has-error .error-description {
  color: #ff4136;
  margin-top: 5px;
}

form .has-error input[type=text],
form .has-error input[type=email],
form .has-error input[type=phone],
form .has-error input[type=password],
form .has-error input[type=number],
form .has-error input[type=search],
form .has-error textarea {
  border-color: #ff4136;
}

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px;
}

.alert:before, .alert:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.alert:after {
  clear: both;
}

.alert.alert-danger {
  background: #ffeae9;
  border-color: #ff4136;
  color: #e90d00;
}

.alert.alert-success {
  background: #e6f9e8;
  border-color: #2ecc40;
  color: #25a233;
}

.alert.alert-warning {
  background: #fff2e7;
  border-color: #ff851b;
  color: #e76b00;
}

.alert.alert-info {
  background: #cfe9ff;
  border-color: #0074d9;
  color: #0059a6;
}

.alert.alert-empty {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  text-align: center;
}

.alert p:last-child {
  margin-bottom: 0;
}

#menu nav ul li a:hover, #menu nav ul li a.active, .top-header .user-pref#search:hover {
  background-color: #1ca7e0;
  color: #ffffff;
}

#menu {
  padding: 0;
}

#menu nav {
  float: right;
}

#menu nav ul {
  list-style: none;
  position: relative;
  float: left;
  padding: 0;
  margin: 30px 0 30px 15px;
}

#menu nav ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#menu nav ul li a {
  color: #004161;
  display: block;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

#menu nav ul li:hover > ul {
  display: block;
}

#menu nav ul li ul {
  color: white;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  z-index: 99999;
  padding-top: 35px;
  margin: 0;
}

#menu nav ul li ul li {
  float: none;
  width: 200px;
}

#menu nav ul li ul li a {
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  background-color: rgba(28, 167, 224, 0.9);
  color: white;
  padding: 10px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#menu nav ul li ul li:hover > a {
  background-color: #005F90;
}

#menu nav ul li ul li ul {
  top: 0;
  right: 100%;
  padding: 0;
  text-indent: 3px;
  font-size: 13px;
}

* {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.valign {
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 40px 0;
}

.subtle {
  color: #aaa;
}

.relative {
  position: relative;
}

body {
  font-family: 'MicrosoftYahei' !important;
}

.limetree-container {
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 1200px) {
  .limetree-container {
    margin: 0 15px;
  }
}

.slick-slide, .slick-slide *, *:focus {
  outline: none !important;
}

.swal2-popup .swal2-styled:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-icon.swal2-success, .swal2-icon.swal2-error {
  box-sizing: initial !important;
}

.swal2-content {
  font-size: 15px !important;
}

.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.dark-blue {
  color: #004161;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-lg-inline {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-md-inline {
    display: block;
  }
}

.center-of-unknown:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.center-of-unknown > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#header-content {
  position: relative;
}

.top-header {
  background-color: #2C2C2C;
  padding: 0;
  color: #ffffff;
  /*end of USER-PREF*/
}

.top-header .user-pref {
  float: right;
  /*end of currency*/
  /*end of search*/
}

.top-header .user-pref#currency .currency-cont {
  margin: 0;
  padding: 0;
  /*end of li*/
}

.top-header .user-pref#currency .currency-cont li {
  display: inline-block;
  border-left: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0;
  padding: 10px;
  /*end of a*/
}

.top-header .user-pref#currency .currency-cont li * {
  display: inline-block;
  vertical-align: middle;
}

.top-header .user-pref#currency .currency-cont li a {
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  text-decoration: none;
  line-height: 1;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#currency .currency-cont li .flag {
  vertical-align: middle;
}

.top-header .user-pref#search {
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-left: 1px solid #151515;
  border-right: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0, rgba(255, 255, 255, 0.2) 1px 0 0 0;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#search.active {
  background-color: #369FDC;
}

.bottom-header #logo {
  margin: 30px 0;
  padding: 0;
}

#menuBtn {
  font-size: 30px;
  color: #84B736;
}

#menuBtn a {
  color: #84B736;
}

#banner {
  position: relative;
  transition: all 0.5s ease-in-out;
  margin-top: -48px;
  /*end of slides*/
  /*end of descs*/
}

@media (max-width: 991px) {
  #banner {
    margin-top: 0;
  }
}

#banner .slides {
  margin-bottom: 0;
}

#banner .slides .slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

@media (max-width: 768px) {
  #banner .slides .slide {
    height: 480px;
  }
}

@media (max-width: 480px) {
  #banner .slides .slide {
    height: 220px;
  }
}

#banner .slides .slide[href="#"] {
  cursor: default;
}

#banner .slides .slick-dots {
  bottom: 0;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  #banner .slides .slick-dots {
    display: none !important;
  }
}

#banner .slides .slick-dots li button:before {
  font-size: 15px;
  opacity: 1;
  color: #FFFFFF;
  transition: all 0.3s linear;
}

#banner .slides .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #84B736;
}

#banner .descs {
  width: 100%;
  max-width: 620px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 768px) {
  #banner .descs {
    max-width: 96%;
    text-align: center;
  }
}

#banner .descs.initial .desc.slick-current .title {
  text-shadow: 1px 1px 4px black;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s;
}

#banner .descs.initial .desc.slick-current p {
  text-shadow: 1px 1px 4px black;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.5s;
}

#banner .descs.initial .desc.slick-current .link {
  text-shadow: 1px 1px 4px black;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2s;
}

#banner .descs .desc .title {
  color: #FFFFFF;
  font-family: 'MicrosoftYahei';
  text-shadow: 1px 1px 4px black;
  opacity: 0;
  margin-bottom: 10px;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s;
}

#banner .descs .desc p {
  color: #FFFFFF;
  font-family: 'MicrosoftYahei';
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 25px;
  text-shadow: 1px 1px 4px black;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.5s;
}

#banner .descs .desc .link {
  color: #84B736;
  background: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 15px 35px;
  border-radius: 30px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2s;
}

#banner .descs .desc .link:hover {
  background: #84B736;
  color: #FFFFFF;
}

#banner .descs.slick-slider .slick-dots {
  bottom: 0;
  left: 0;
}

#banner .descs.slick-slider .slick-dots li button {
  width: 10px;
  height: 10px;
}

#banner .descs.slick-slider .slick-dots li button:before {
  font-size: 10px;
  color: #1ca7e0;
  transition: all 0.5s ease;
  opacity: 0.5;
  width: auto;
  height: auto;
}

#banner .descs.slick-slider .slick-dots li.slick-active button:before, #banner .descs.slick-slider .slick-dots li button:hover:before {
  color: #004161;
  opacity: 1;
  font-size: 12px;
}

#banner .descs.slick-slider .slick-prev:before, #banner .descs.slick-slider .slick-next:before {
  color: #004161;
}

#banner .descs.slick-slider .slick-slide:focus {
  outline: none;
}

#inner-banner .slide {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#breadcrumb {
  display: none;
  background-color: white;
  padding: 15px 0;
  font-size: 13px;
}

@media (min-width: 768px) {
  #breadcrumb {
    display: block;
  }
}

#breadcrumb .text {
  color: #004161;
}

#breadcrumb .crumb {
  color: #747474;
}

#breadcrumb .crumb:after {
  font-family: 'fontAwesome';
  content: '\f105';
  color: #747474;
  margin: 0 8px;
}

#breadcrumb .crumb:last-child:after {
  content: '';
  display: none;
}

@media (max-width: 991px) {
  #content {
    margin-top: 90px;
  }
}

#content .page-content h1, #content .page-content h2, #content .page-content h3 {
  color: #313131;
  padding: 15px 0;
}

@media (max-width: 991px) {
  #content .page-content h1, #content .page-content h2, #content .page-content h3 {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  #content .page-content h1, #content .page-content h2, #content .page-content h3 {
    padding: 0;
    margin-bottom: 10px;
  }
}

#content .page-content p {
  color: #000000;
  font-size: 12px;
  text-align: justify;
}

#content .page-content img {
  max-width: 100%;
  display: block;
}

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#content .page-content .read-more:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#content .page-content .left-content {
  background-color: #ffffff;
}

#content .page-content .left-content > *:last-child {
  margin-bottom: 30px;
}

#content .page-content .right-content {
  background-color: #F3F3F3;
}

#content .page-content .right-content > *:last-child {
  margin-bottom: 30px;
}

#content .bottom-content {
  margin-top: 30px;
}

#news-mini {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
  /*end of news*/
}

#news-mini:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .title-cont {
  position: relative;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  #news-mini .title-cont {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0083B4;
    box-shadow: #16BCF9 0 1px 0 0;
    transition: all 0.5s ease-in-out;
  }
}

#news-mini .title-cont .title {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  #news-mini .title-cont .title {
    display: inline-block;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #news-mini .title-cont .title {
    display: inline-block;
  }
}

#news-mini .title-cont .link {
  color: #004161;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

#news-mini .title-cont .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: white;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid white;
  border-left-color: inherit;
}

@media (max-width: 768px) {
  #news-mini .title-cont .link {
    float: right;
  }
}

#news-mini .news {
  transition: opacity 0.5s ease-in-out;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
}

@media (max-width: 991px) {
  #news-mini .news {
    border: none;
    box-shadow: none;
  }
}

#news-mini .news .slide:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .news .slide > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .news .slide .date {
  color: #004161;
  text-align: center;
}

#news-mini .news .slide .date .day {
  font-size: 30px;
}

#news-mini .news .slide .date .month {
  font-size: 14px;
}

#news-mini .news .slide .desc .title {
  color: #004161;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#news-mini .news .slide .desc p {
  color: white;
  font-size: 13px;
  margin: 0;
}

#news-mini .news .slide .desc a {
  display: block;
}

#news-mini .news .slide:focus {
  outline: none;
}

#news-mini .slide-arrow:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .slide-arrow > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .slide-arrow .slide-left, #news-mini .slide-arrow .slide-right {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-right {
  margin-left: 5px;
}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
}

#home-video-cont a {
  display: block;
  width: 100%;
}

#home-video-cont .vid {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#home-customer-value {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
}

#home-customer-value .title {
  color: #004161;
}

#home-customer-value img {
  margin-bottom: 15px;
}

#home-customer-value p {
  color: white;
}

#home-any-last-words {
  margin: 30px 0;
  color: #1ca7e0;
}

#popUpsContainer {
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

footer #footer-content {
  background-color: #2C2C2C;
  border-top: 5px solid #1ca7e0;
  padding: 0 0 40px;
}

footer #footer-content h4 {
  color: #1ca7e0;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;
}

footer #footer-content .bottom-footer {
  border-top: 1px solid #151515;
  box-shadow: #424242 0 -1px 0 0;
  padding-top: 15px;
}

footer #footer-content .bottom-footer .links {
  color: white;
}

footer #footer-content .bottom-footer .site-map {
  color: white;
}

.top-footer {
  background-color: #2C2C2C;
  padding-bottom: 30px;
}

.top-footer h4 {
  color: #1ca7e0;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;
}

.top-footer > * {
  padding-top: 20px;
  /*end of nth-child(2)*/
  /*end of first-child()*/
}

.top-footer > *:nth-child(2), .top-footer > *:nth-child(3) {
  border-right: 1px solid #151515;
  box-shadow: #424242 1px 0 0 0;
}

@media (max-width: 991px) {
  .top-footer > *:nth-child(2) {
    box-shadow: none;
    border: 0;
  }
}

@media (max-width: 768px) {
  .top-footer > *:first-child {
    text-align: left;
  }
}

.top-footer p {
  font-size: 10px;
  color: white;
  margin: 0;
  line-height: 1.4;
}

.top-footer .footer-logo img {
  margin-bottom: 10px;
}

.top-footer .hotline {
  margin-top: 15px;
}

.top-footer .hotline i {
  color: #1ca7e0;
  vertical-align: middle;
}

.top-footer .hotline .phone-number {
  display: inline-block;
  color: white;
  vertical-align: middle;
  margin: 0;
}

.top-footer .hotline .phone-number > a {
  color: white;
}

.top-footer .quick-nav a {
  display: block;
  color: white;
  margin-bottom: 5px;
  font-size: 12px;
}

.top-footer .quick-nav a:after {
  left: 0;
}

.top-footer .quick-nav a:hover {
  text-decoration: underline;
}

.top-footer .quick-nav a:hover:after {
  left: 100%;
}

.top-footer .quick-nav a i {
  color: #1ca7e0;
  padding: 0 5px;
}

.top-footer .address {
  margin-bottom: 10px;
}

#social-icon {
  margin-bottom: 5px;
}

#social-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

#social-icon ul li {
  font-size: 16px;
  display: inline-block;
}

#social-icon ul li:last-child a {
  margin: 0;
}

#social-icon ul li a {
  color: #FFFFFF;
  border-radius: 50px;
  border-color: #FFFFFF;
  border: 1px solid;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s linear;
}

#social-icon ul li a i {
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3s linear;
}

#social-icon ul li a:hover {
  border-color: #84B736;
}

#social-icon ul li a:hover i {
  color: #84B736;
}

#social-icon ul li a img {
  max-height: 18px;
  vertical-align: bottom;
}

/*inner page*/
#inner {
  background-color: white;
}

#inner.no-banner {
  margin-top: 0;
}

@media (max-width: 991px) {
  #inner.no-banner {
    padding-top: 60px;
  }
}

#inner #newsletter {
  margin: 0 -15px 30px;
  display: block;
  min-height: 100px;
  width: auto;
}

.header-searchbar {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transition: all 0.5s linear;
}

.header-searchbar.hide-it {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s linear;
}

.header-searchicon {
  position: absolute;
  top: 50%;
  right: 0;
  background: #84B736;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  line-height: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s linear;
}

.header-searchicon:hover {
  background: #747474;
}

#searchBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  text-align: center;
}

#searchBar form {
  margin: 0;
  height: 100%;
}

#searchBar input {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 0;
  box-shadow: none;
  background: transparent;
  color: #747474;
  font-size: 18px;
  font-family: 'MicrosoftYahei';
}

#searchBar input::-webkit-input-placeholder {
  color: #747474;
  font-size: 18px;
  font-family: 'MicrosoftYahei';
}

#searchBar input::-moz-placeholder {
  color: #747474;
  font-size: 18px;
  font-family: 'MicrosoftYahei';
}

#searchBar input:-moz-placeholder {
  color: #747474;
  font-size: 18px;
  font-family: 'MicrosoftYahei';
}

#searchBar input:-ms-input-placeholder {
  color: #747474;
  font-size: 18px;
  font-family: 'MicrosoftYahei';
}

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px;
}

.editor-mode .home-cms-introduction .home-cms-introduction-right {
  display: block !important;
}

.editor-mode .home-cms-amenities .home-cms-amenities-listing > div, .editor-mode .home-cms-special .home-cms-special-listing > div {
  opacity: 1 !important;
}

.editor-mode .home-cms-special .home-cms-special-listing > div .home-cms-special-item {
  opacity: 1 !important;
}

@media (min-width: 768px) {
  #divChangeViews {
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

header {
  width: 100%;
}

header.sticky {
  position: fixed;
  height: auto;
  z-index: 99999;
}

header.sticky .header-wrapper {
  opacity: 0;
  max-height: 0;
  z-index: -1;
}

@media (max-width: 991px) {
  header.sticky .header-wrapper {
    opacity: 1;
    max-height: 100px;
    z-index: 99999;
  }
}

@media (max-width: 991px) {
  header {
    position: fixed;
    height: auto;
    z-index: 99999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.header-wrapper {
  position: relative;
  background: #FFFFFF;
  z-index: 10001;
  max-height: 100px;
  opacity: 1;
  transition: all 0.3s linear;
}

.header-wrapper .header-bottom-background {
  background: #313131;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 50px;
}

.header-wrapper .header {
  display: flex;
  position: relative;
}

.header-wrapper .header .header-left {
  position: absolute;
  left: 0;
  top: 0;
}

.header-wrapper .header .header-left a img {
  width: 100%;
}

@media (max-width: 700px) {
  .header-wrapper .header .header-left a img {
    max-width: 120px;
  }
}

.header-wrapper .header .header-right {
  padding-left: 200px;
  width: 100%;
}

@media (max-width: 700px) {
  .header-wrapper .header .header-right {
    padding-left: 140px;
  }
}

.header-wrapper .header .header-right .header-right-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-wrapper .header .header-right .header-right-top .header-information {
  padding: 20px 0;
}

@media (max-width: 991px) {
  .header-wrapper .header .header-right .header-right-top .header-information {
    padding: 15px 10px 15px 0;
  }
}

.header-wrapper .header .header-right .header-right-top .header-information .header-title {
  color: #747474;
  margin-bottom: 10px;
  line-height: 0.7;
}

@media (max-width: 991px) {
  .header-wrapper .header .header-right .header-right-top .header-information .header-title {
    font-size: 22px;
  }
}

@media (max-width: 700px) {
  .header-wrapper .header .header-right .header-right-top .header-information .header-title {
    opacity: 0;
    height: 50px;
  }
}

.header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item {
  color: #747474;
  font-family: 'MicrosoftYahei';
  padding-right: 20px;
}

@media (max-width: 1024px) {
  .header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item:first-child {
    display: block;
    margin-bottom: 3px;
  }
}

@media (max-width: 1024px) {
  .header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item {
    display: none !important;
  }
}

.header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item i {
  color: #84B736;
  padding-right: 5px;
  max-width: 28px;
}

.header-wrapper .header .header-right .header-right-top .header-information .header-info .header-info-item i.fa-flip-horizontal {
  padding-right: 0;
  padding-left: 10px;
}

.header-wrapper .header .header-right .header-right-top .header-weather {
  width: 225px;
}

.daterangepicker {
  border-radius: 0;
}

.daterangepicker.dropdown-menu {
  z-index: 99999;
}

@media (max-width: 480px) {
  .daterangepicker {
    max-width: 253px !important;
  }
}

.daterangepicker .calendar th, .daterangepicker .calendar td {
  font-family: 'MicrosoftYahei';
  font-size: 12px;
}

.daterangepicker td.today.active, .daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #84B736;
  border-color: #84B736;
}

.daterangepicker td.in-range {
  background: #F1F1F1;
}

.daterangepicker .ranges {
  float: none !important;
  width: auto !important;
  text-align: center;
}

.daterangepicker .ranges .daterangepicker_start_input, .daterangepicker .ranges .daterangepicker_end_input {
  display: none;
}

.daterangepicker .ranges .btn {
  background: #313131;
  padding: 10px 40px;
  display: inline-block;
  color: white;
  font-family: 'MicrosoftYahei';
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  border-radius: 0;
  border: none;
  height: auto;
  line-height: inherit;
  transition: all 0.3s linear;
  margin-top: 5px;
}

.daterangepicker .ranges .btn:hover {
  background: #84B736;
}

@media (max-width: 480px) {
  .daterangepicker .ranges .btn.applyBtn {
    margin-bottom: 0;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .daterangepicker .ranges .btn.cancelBtn {
    margin-bottom: 0;
    margin-top: -10px;
  }
}

.header-bottom {
  background: #313131;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 95;
}

.header-bottom .limetree-container {
  position: relative;
}

.header-bottom .header-booking {
  display: flex;
  align-items: center;
  padding: 9px 150px 10px 200px;
}

.header-bottom .header-booking .header-booking-title {
  padding-right: 15px;
}

.header-bottom .header-booking .header-booking-title .booking-title {
  color: #FFFFFF;
  letter-spacing: 1px;
  display: block;
}

.header-bottom .header-booking .header-booking-title .booking-subtitle {
  color: #84B736;
  letter-spacing: 1px;
  display: block;
}

.header-booking-form {
  width: calc(100% - 120px);
  display: flex;
  margin: 0;
}

@media (max-width: 1200px) {
  .header-booking-form {
    flex-wrap: wrap;
  }
}

.header-booking-form .booking-form {
  color: #313131;
  background: #FFFFFF;
  padding: 9px 10px;
  margin-right: 10px;
  width: 25%;
  position: relative;
}

@media (max-width: 1200px) {
  .header-booking-form .booking-form {
    width: calc(50% - 10px);
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .header-booking-form .booking-form {
    width: 100%;
    margin-right: 0;
  }
}

.header-booking-form .booking-form .booking_checkin_text, .header-booking-form .booking-form .booking_checkout_text, .header-booking-form .booking-form .booking-pax-text, .header-booking-form .booking-form i {
  cursor: pointer;
}

.header-booking-form .booking-form.booking-pax {
  width: 30%;
}

@media (max-width: 1200px) {
  .header-booking-form .booking-form.booking-pax {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .header-booking-form .booking-form.booking-pax {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.header-booking-form .booking-form.booking-promotion {
  width: 20%;
}

@media (max-width: 1200px) {
  .header-booking-form .booking-form.booking-promotion {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .header-booking-form .booking-form.booking-promotion {
    width: 100%;
    margin-right: 0;
  }
}

.header-booking-form .booking-form.booking-promotion input {
  box-shadow: none;
  border: none;
  height: auto;
  width: 100%;
  padding: 0;
}

.header-booking-form .booking-form i {
  float: right;
  color: #84B736;
}

.booking-pax-expanded {
  position: absolute;
  background: white;
  opacity: 1;
  visibility: visible;
  z-index: 99;
  left: 0;
  top: 128%;
  padding: 15px;
  transition: all 0.3s linear;
}

.booking-pax-expanded.hide-it {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  top: 100%;
  transition: all 0.3s linear;
}

@media (max-width: 480px) {
  .booking-pax-expanded {
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.booking-pax-expanded .booking-pax-table {
  display: table;
}

.booking-pax-expanded .booking-pax-table .booking-pax-row {
  display: table-row;
}

.booking-pax-expanded .booking-pax-table .booking-pax-row .booking-pax-cell {
  display: table-cell;
  text-align: center;
  font-family: 'MicrosoftYahei';
  font-size: 15px;
  padding: 10px 20px;
  color: white;
}

.booking-pax-expanded .booking-pax-table .booking-pax-row .booking-pax-cell input {
  max-width: 70px;
  text-align: center;
  border-radius: 0;
  color: #313131;
}

@media (max-width: 480px) {
  .booking-pax-expanded .booking-pax-table .booking-pax-row .booking-pax-cell input {
    max-width: 68px;
  }
}

@media (max-width: 325px) {
  .booking-pax-expanded .booking-pax-table .booking-pax-row .booking-pax-cell input {
    max-width: 50px;
  }
}

.booking-pax-expanded .booking-pax-table .booking-pax-row .booking-pax-cell input:focus {
  border-color: #84B736;
}

.booking-pax-expanded .booking-pax-table .booking-pax-header {
  background: #313131;
}

.booking-pax-expanded .booking-pax-table .booking-pax-inputs {
  background: #F1F1F1;
}

.booking-pax-expanded .booking-pax-confirm {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.booking-pax-expanded .booking-pax-confirm > span {
  background: #313131;
  padding: 10px 40px;
  display: inline-block;
  color: white;
  font-family: 'MicrosoftYahei';
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.booking-pax-expanded .booking-pax-confirm > span:hover {
  background: #84B736;
}

.header-booking-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.header-booking-button .booking-button {
  color: #FFFFFF;
  text-transform: uppercase;
  background: #84B736;
  height: 100%;
  width: 150px;
  text-align: center;
  line-height: 52px;
}

@media (max-width: 1200px) {
  .header-booking-button .booking-button {
    line-height: 95px;
  }
}

#mobile-booking {
  width: 100%;
  padding: 15px 20px;
  background: #F1F1F1;
}

#mobile-booking .header-booking-form {
  width: 100%;
}

#mobile-booking .header-booking-button {
  position: relative;
  text-align: center;
  margin-top: 15px;
}

#mobile-booking .header-booking-button .booking-button {
  height: auto;
  line-height: initial !important;
  padding: 10px;
  border-radius: 50px;
}

#mobile-menu.mm-hasnavbar-top-5 .mm-panel {
  top: 200px;
}

#mobile-menu .mm-navbar {
  border: none !important;
}

#mobile-menu #searchBar form {
  margin: 0 10px;
  background: #FFFFFF;
}

#mobile-menu .header-weather {
  display: block !important;
}

.header-menu {
  background: rgba(226, 226, 226, 0.9);
  position: -webkit-sticky;
  position: sticky;
  z-index: 95;
}

.header-menu .limetree-container {
  position: relative;
}

.header-menu nav {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transition: all 0.5s linear;
}

.header-menu nav.hide-it {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s linear;
}

.header-menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.header-menu nav ul li {
  color: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none !important;
  padding: 15px 20px;
}

.header-menu nav ul li a {
  font-size: 15px;
  color: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none !important;
}

.header-menu nav ul li ul {
  display: none !important;
}

.inner-navigation {
  display: flex;
}

@media (max-width: 700px) {
  .inner-navigation {
    flex-wrap: wrap;
  }
}

.inner-navigation .inner-navigation-main {
  padding-right: 30px;
  max-width: 210px;
  min-width: 210px;
}

@media (max-width: 700px) {
  .inner-navigation .inner-navigation-main {
    max-width: 100%;
    width: 100%;
  }
}

.inner-navigation .inner-navigation-main a {
  color: #313131;
  padding: 10px 0;
  border-top: 2px solid transparent;
  transition: all 0.3s linear;
}

.inner-navigation .inner-navigation-main a.current-page {
  color: #84B736;
  border-color: #84B736;
}

.inner-navigation .inner-navigation-main a:hover {
  color: #84B736;
}

.inner-navigation .inner-navigation-list {
  overflow: hidden;
  position: relative;
  width: calc(100% - 210px);
  height: 50px;
  max-height: 50px;
  overflow: hidden;
}

@media (max-width: 700px) {
  .inner-navigation .inner-navigation-list {
    max-width: 100%;
    width: 100%;
  }
  .inner-navigation .inner-navigation-list .subNavigation {
    padding: 0 !important;
  }
}

.inner-navigation .inner-navigation-list .slick-slider {
  margin-bottom: 0;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick {
  padding-right: 130px;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick .slick-track {
  min-width: 100%;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick .subNavigation {
  padding: 0 25px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  cursor: default;
  transition: all 0.3s linear;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick .subNavigation.slick-active {
  opacity: 1 !important;
  width: auto !important;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick .subNavigation.slick-active a {
  cursor: pointer;
  position: relative;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick .subNavigation.slick-active a:before {
  content: '';
  background: black;
  height: 10px;
  width: 2px;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick a {
  color: #313131;
  padding: 10px 0;
  border-top: 2px solid transparent;
  transition: all 0.3s linear;
  cursor: default;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick a.current-page {
  color: #84B736;
  border-color: #84B736;
}

.inner-navigation .inner-navigation-list > div.inner-navigation-slick a:hover {
  color: #84B736;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow {
  position: relative;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-arrow {
  position: relative;
  display: inline-block;
  right: 0;
  left: 0;
  width: 45px;
  height: 45px;
  border: 1px solid grey;
  border-radius: 100px;
  transition: all 0.3s linear;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-arrow:before {
  color: #B2B2B2;
  opacity: 1;
  font-family: 'fontAwesome';
  font-size: 13px;
  transition: all 0.3s linear;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-arrow:hover {
  border-color: #84B736;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-arrow:hover:before {
  color: #84B736;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-prev:before {
  content: '\f053';
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-next {
  margin-left: 10px;
}

.inner-navigation .inner-navigation-list .inner-navigation-arrow-wrapper .inner-navigation-arrow .slick-next:before {
  content: '\f054';
}

.home-cms-introduction .limetree-container {
  border-bottom: 1px solid #E2E2E2;
}

@media (max-width: 1200px) {
  .home-cms-introduction .limetree-container {
    padding: 35px 0;
  }
}

.home-cms-introduction .home-cms-introduction-left {
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .home-cms-introduction .home-cms-introduction-left {
    width: 100%;
  }
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top {
  margin-bottom: 60px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-title {
  color: #313131;
  margin-bottom: 5px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-subtitle {
  color: #313131;
  margin-bottom: 35px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-description {
  color: #313131;
  margin-bottom: 35px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-links > div a {
  color: #B2B2B2;
  letter-spacing: 1px;
  padding: 10px 20px;
  border: 1px solid #B2B2B2;
  border-radius: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s linear;
}

@media (max-width: 480px) {
  .home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-links > div a {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-top .home-cms-introduction-links > div a:hover {
  color: #84B736;
  border-color: #84B736;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-title {
  color: #313131;
  margin-bottom: 20px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-items {
  margin-bottom: 20px;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-items .home-cms-award-item img {
  height: auto;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-link a {
  color: #B2B2B2;
  letter-spacing: 1px;
  padding: 10px 20px;
  border: 1px solid #B2B2B2;
  border-radius: 25px;
  margin-right: 10px;
  transition: all 0.3s linear;
}

.home-cms-introduction .home-cms-introduction-left > div .home-cms-introduction-bottom .home-cms-award-link a:hover {
  color: #84B736;
  border-color: #84B736;
}

@media (max-width: 1200px) {
  .home-cms-introduction .home-cms-introduction-right {
    display: none;
  }
}

.home-cms-introduction .home-cms-introduction-right > div {
  text-align: right;
}

.home-cms-amenities {
  padding: 60px 0 40px;
}

.home-cms-amenities .home-cms-amenities-title {
  color: #313131;
  text-align: center;
  margin-bottom: 5px;
}

.home-cms-amenities .home-cms-amenities-subtitle {
  color: #313131;
  text-align: center;
  margin-bottom: 40px;
}

.home-cms-amenities .home-cms-amenities-listing > div {
  opacity: 0;
}

.home-cms-amenities .home-cms-amenities-listing > div .home-cms-amenities-item {
  margin-bottom: 35px;
  vertical-align: top;
}

.home-cms-amenities .home-cms-amenities-listing > div .home-cms-amenities-item .home-cms-amenities-image {
  margin-bottom: 20px;
  height: 70px;
}

.home-cms-amenities .home-cms-amenities-listing > div .home-cms-amenities-item .home-cms-amenities-image img {
  margin: 0 auto;
}

.home-cms-amenities .home-cms-amenities-listing > div .home-cms-amenities-item .home-cms-amenities-name {
  color: #313131;
  font-size: 15px;
  text-align: center;
  max-width: 120px;
  margin: 0 auto;
}

.home-cms-amenities .home-cms-amenities-button {
  text-align: center;
}

.home-cms-amenities .home-cms-amenities-button a {
  color: #84B736;
  background: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 15px 35px;
  border-radius: 30px;
  transition: all 0.3s linear;
}

.home-cms-amenities .home-cms-amenities-button a:hover {
  background: #84B736;
  color: #FFFFFF;
}

.home-cms-amenities .slick-arrow {
  z-index: 1;
  width: 40px;
  height: 40px;
  background: #313131;
  text-align: center;
  transition: 0.25s;
  border-radius: 50px;
}

.home-cms-amenities .slick-arrow:before {
  transition: 0.25s;
  font-family: 'fontAwesome';
  font-size: 12px;
  line-height: 40px;
  opacity: 1;
}

.home-cms-amenities .slick-arrow:hover {
  background: #84B736;
}

.home-cms-amenities .slick-arrow.slick-prev {
  left: 15px;
}

@media (max-width: 991px) {
  .home-cms-amenities .slick-arrow.slick-prev {
    left: 0;
  }
}

.home-cms-amenities .slick-arrow.slick-prev:before {
  content: '\f053';
}

.home-cms-amenities .slick-arrow.slick-next {
  right: 15px;
}

@media (max-width: 991px) {
  .home-cms-amenities .slick-arrow.slick-next {
    right: 0;
  }
}

.home-cms-amenities .slick-arrow.slick-next:before {
  content: '\f054';
}

.home-cms-special {
  padding: 60px 0 40px;
  background: #F1F1F1;
}

.home-cms-special .home-cms-special-title {
  color: #313131;
  text-align: center;
  margin-bottom: 5px;
}

.home-cms-special .home-cms-special-subtitle {
  color: #313131;
  text-align: center;
  margin-bottom: 40px;
}

.home-cms-special .home-cms-special-listing > div {
  opacity: 0;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item {
  padding: 10px 0;
  opacity: 0.3;
  z-index: -1;
  transition: all 0.3s linear;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item.slick-active, .home-cms-special .home-cms-special-listing > div .home-cms-special-item.slick-current, .home-cms-special .home-cms-special-listing > div .home-cms-special-item.slick-center {
  opacity: 1;
  z-index: 1;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item:hover > span {
  box-shadow: 0 0 10px 2px #E2E2E2;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item:hover .home-cms-special-item-title, .home-cms-special .home-cms-special-listing > div .home-cms-special-item:hover .home-cms-special-item-description {
  color: #84B736 !important;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span {
  display: block;
  margin: 0 15px;
  box-shadow: none;
  transition: all 0.3s linear;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-image {
  display: block;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-image img {
  width: 100%;
  height: auto;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-text {
  padding: 25px;
  background: #FFFFFF;
  display: block;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-text .home-cms-special-item-title {
  color: #313131;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s linear;
}

.home-cms-special .home-cms-special-listing > div .home-cms-special-item > span .home-cms-special-item-text .home-cms-special-item-description {
  color: #313131;
  display: block;
  transition: all 0.3s linear;
}

.home-cms-special .home-cms-special-button {
  text-align: center;
}

.home-cms-special .home-cms-special-button a {
  color: #84B736;
  background: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 15px 35px;
  border-radius: 30px;
  transition: all 0.3s linear;
}

.home-cms-special .home-cms-special-button a:hover {
  background: #84B736;
  color: #FFFFFF;
}

.home-cms-special .slick-arrow {
  z-index: 1;
  width: 40px;
  height: 40px;
  background: #313131;
  text-align: center;
  transition: 0.25s;
  border-radius: 50px;
}

.home-cms-special .slick-arrow:before {
  transition: 0.25s;
  font-family: 'fontAwesome';
  font-size: 12px;
  line-height: 40px;
  opacity: 1;
}

.home-cms-special .slick-arrow:hover {
  background: #84B736;
}

.home-cms-special .slick-arrow.slick-prev {
  left: 15px;
}

@media (max-width: 480px) {
  .home-cms-special .slick-arrow.slick-prev {
    left: -10px;
  }
}

.home-cms-special .slick-arrow.slick-prev:before {
  content: '\f053';
}

.home-cms-special .slick-arrow.slick-next {
  right: 15px;
}

@media (max-width: 480px) {
  .home-cms-special .slick-arrow.slick-next {
    right: -10px;
  }
}

.home-cms-special .slick-arrow.slick-next:before {
  content: '\f054';
}

.inner .text-editor-content {
  padding-top: 20px;
}

.inner .home-cms-accommodation {
  padding: 0 0 40px;
}

.inner .home-cms-accommodation-listing > div {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.inner .home-cms-accommodation-listing > div .home-cms-accommodation-item {
  width: calc(100% / 3);
  padding: 15px 0 !important;
}

@media (max-width: 1200px) {
  .inner .home-cms-accommodation-listing > div .home-cms-accommodation-item {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .inner .home-cms-accommodation-listing > div .home-cms-accommodation-item {
    width: 100%;
  }
}

.inner .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-image {
  padding: 20px;
}

.inner .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-link {
  background: #313131 !important;
  color: #FFFFFF !important;
  border: 1px solid #313131 !important;
}

.inner .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-link:after {
  font-family: 'fontAwesome';
  font-size: 10px;
  content: '\f054';
  color: #FFFFFF;
  padding-left: 10px;
  transition: all 0.3s linear;
}

.inner .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-link:hover {
  color: #84B736 !important;
  border: 1px solid #313131 !important;
}

.inner .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-link:hover:after {
  color: #84B736;
}

.home-cms-accommodation {
  padding: 60px 0 40px;
}

.home-cms-accommodation .home-cms-accommodation-title {
  color: #313131;
  text-align: center;
  margin-bottom: 30px;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .slick-list {
  margin: 0 20px;
}

@media (max-width: 480px) {
  .home-cms-accommodation .home-cms-accommodation-listing > div .slick-list {
    margin: 0;
  }
  .home-cms-accommodation .home-cms-accommodation-listing > div .slick-list .wrap {
    margin: 0 !important;
  }
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item {
  padding: 10px 0;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item:hover > span {
  box-shadow: 0 0 10px 2px #E2E2E2;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span {
  display: block;
  margin: 0 15px;
  border: 1px solid #E2E2E2;
  box-shadow: none;
  transition: all 0.3s linear;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-image {
  display: block;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-image img {
  width: 100%;
  height: auto;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text {
  display: block;
  padding: 30px;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-title {
  color: #313131;
  display: block;
  text-align: center;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-divider {
  width: 30px;
  height: 1px;
  background: #84B736;
  content: '';
  display: block;
  margin: 20px auto;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description {
  display: block;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table {
  display: table;
  margin: auto;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row {
  display: table-row;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns {
  display: table-cell;
  padding: 5px 10px;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns.accommodation-icon {
  text-align: center;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns.accommodation-icon .fa {
  color: #84B736;
  font-size: 16px;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns.accommodation-label {
  color: #313131;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-description .accommodation-table .accommodation-row .accommodation-columns.accommodation-desc {
  color: #313131;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-link {
  color: #B2B2B2;
  letter-spacing: 1px;
  padding: 10px 20px;
  border: 1px solid #B2B2B2;
  border-radius: 25px;
  display: block;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 30px auto;
  transition: all 0.3s linear;
}

.home-cms-accommodation .home-cms-accommodation-listing > div .home-cms-accommodation-item > span .home-cms-accommodation-item-text .home-cms-accommodation-item-link:hover {
  color: #84B736;
  border-color: #84B736;
}

.home-cms-accommodation .home-cms-accommodation-button {
  text-align: center;
}

.home-cms-accommodation .home-cms-accommodation-button a {
  color: #84B736;
  background: #313131;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 15px 35px;
  border-radius: 30px;
  transition: all 0.3s linear;
}

.home-cms-accommodation .home-cms-accommodation-button a:hover {
  background: #84B736;
  color: #FFFFFF;
}

.home-cms-accommodation .slick-arrow {
  z-index: 1;
  width: 40px;
  height: 40px;
  background: #313131;
  text-align: center;
  transition: 0.25s;
  border-radius: 50px;
}

.home-cms-accommodation .slick-arrow:before {
  transition: 0.25s;
  font-family: 'fontAwesome';
  font-size: 12px;
  line-height: 40px;
  opacity: 1;
}

.home-cms-accommodation .slick-arrow:hover {
  background: #84B736;
}

.home-cms-accommodation .slick-arrow.slick-prev {
  left: 15px;
}

.home-cms-accommodation .slick-arrow.slick-prev:before {
  content: '\f053';
}

@media (max-width: 480px) {
  .home-cms-accommodation .slick-arrow.slick-prev {
    left: -10px;
  }
}

.home-cms-accommodation .slick-arrow.slick-next {
  right: 15px;
}

.home-cms-accommodation .slick-arrow.slick-next:before {
  content: '\f054';
}

@media (max-width: 480px) {
  .home-cms-accommodation .slick-arrow.slick-next {
    right: -10px;
  }
}

.home-cms-bookdirect {
  padding-top: 25px;
}

.home-cms-bookdirect .home-cms-bookdirect-title {
  color: #313131;
  text-align: center;
  margin-bottom: 5px;
}

.home-cms-bookdirect .home-cms-bookdirect-listing {
  padding: 75px 0 50px;
}

@media (max-width: 480px) {
  .home-cms-bookdirect .home-cms-bookdirect-listing {
    padding: 50px 0 50px;
  }
}

.home-cms-bookdirect .home-cms-bookdirect-listing > div {
  display: flex;
}

@media (max-width: 991px) {
  .home-cms-bookdirect .home-cms-bookdirect-listing > div {
    flex-wrap: wrap;
  }
}

.home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item {
  border: 1px solid #E2E2E2;
  width: 25%;
  margin: 0 20px;
  padding: 20px 40px;
}

@media (max-width: 991px) {
  .home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item {
    width: calc(50% - 40px);
    margin: 50px 20px;
    padding: 50px 60px;
  }
}

@media (max-width: 480px) {
  .home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item {
    width: 100%;
    margin: 50px 0 70px;
    padding: 50px 60px 25px;
  }
  .home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item:last-child {
    margin-bottom: 0;
  }
}

.home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item .home-cms-bookdirect-img {
  text-align: center;
  margin-top: -35%;
}

@media (max-width: 1200px) {
  .home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item .home-cms-bookdirect-img {
    margin-top: -65%;
  }
}

.home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item .home-cms-bookdirect-img > div {
  display: inline-block;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border: 1px solid #E2E2E2;
  border-radius: 100px;
  background: #FFFFFF;
}

.home-cms-bookdirect .home-cms-bookdirect-listing > div .home-cms-bookdirect-item .home-cms-bookdirect-text {
  color: #313131;
  text-align: center;
  margin-top: 20px;
}

#footer-top {
  padding: 40px 0;
  background: url("../../img/limetreehotel/bg.png") left center;
}

@media (max-width: 991px) {
  #footer-top .footer-contactus {
    margin-bottom: 25px;
  }
}

#footer-top .footer-contactus .footer-contactus-title {
  color: #313131;
  margin-bottom: 20px;
}

#footer-top .footer-contactus .footer-contactus-content .contactus-left .contactus-item, #footer-top .footer-contactus .footer-contactus-content .contactus-right .contactus-item {
  display: table-row;
}

#footer-top .footer-contactus .footer-contactus-content .contactus-left .contactus-item .contactus-icon, #footer-top .footer-contactus .footer-contactus-content .contactus-right .contactus-item .contactus-icon {
  display: table-cell;
}

#footer-top .footer-contactus .footer-contactus-content .contactus-left .contactus-item .contactus-icon i, #footer-top .footer-contactus .footer-contactus-content .contactus-right .contactus-item .contactus-icon i {
  color: #84B736;
  font-size: 14px;
}

#footer-top .footer-contactus .footer-contactus-content .contactus-left .contactus-item .contactus-text, #footer-top .footer-contactus .footer-contactus-content .contactus-right .contactus-item .contactus-text {
  display: table-cell;
  padding: 5px 20px;
  color: #313131;
}

#footer-top .footer-newsletter .footer-newsletter-title {
  color: #313131;
  margin-bottom: 20px;
}

#newsletter .newsletter-box {
  position: relative;
}

#newsletter .newsletter-box .newsletter-front {
  width: 100%;
  border-radius: 100px;
  padding-right: 200px;
  padding-left: 20px;
  border-color: #FFFFFF;
  box-shadow: none;
  color: #747474;
  font-size: 14px;
  font-family: 'MicrosoftYahei';
}

#newsletter .newsletter-box .newsletter-front::-webkit-input-placeholder {
  color: #B2B2B2;
  font-size: 14px;
  font-family: 'MicrosoftYahei';
}

#newsletter .newsletter-box .newsletter-front::-moz-placeholder {
  color: #B2B2B2;
  font-size: 14px;
  font-family: 'MicrosoftYahei';
}

#newsletter .newsletter-box .newsletter-front:-moz-placeholder {
  color: #B2B2B2;
  font-size: 14px;
  font-family: 'MicrosoftYahei';
}

#newsletter .newsletter-box .newsletter-front:-ms-input-placeholder {
  color: #B2B2B2;
  font-size: 14px;
  font-family: 'MicrosoftYahei';
}

#newsletter .newsletter-box .newsletter-icon-btn {
  color: #FFFFFF;
  letter-spacing: 1px;
  background: #84B736;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s linear;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 50px;
  line-height: 44px;
  border-radius: 0 100px 100px 0;
}

#newsletter .newsletter-box .newsletter-icon-btn:hover {
  background: #747474;
}

#map {
  width: 100%;
  height: 400px;
}

#map .gm-style .gm-style-iw + div {
  display: none;
  /* <-- this will generally work on the fly. */
  visibility: hidden;
  /* this 2 lines below are just for hard hiding. :) */
  opacity: 0;
}

#map .googlemap-title {
  color: #84B736;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

#map .googlemap-address {
  color: #313131;
  margin-bottom: 10px;
}

#map .googlemap-link a {
  color: #FFFFFF;
  background: #84B736;
  padding: 10px 15px;
  border-radius: 20px;
  text-transform: uppercase;
}

#map .googlemap-link a i {
  color: #FFFFFF;
  padding-left: 10px;
}

#map .googlemap-link a:hover {
  background: #313131;
}

#footer-bottom {
  background: #242424;
  padding-top: 30px;
}

#footer-bottom .limetree-container {
  position: relative;
}

#footer-bottom .footer-bottom-info > div {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid #747474;
}

@media (max-width: 480px) {
  #footer-bottom .footer-bottom-info > div {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  #footer-bottom .footer-bottom-info > div .footer-bottom-menu {
    width: 100%;
    display: flex;
  }
}

#footer-bottom .footer-bottom-info > div .footer-bottom-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 60px;
  display: inline-block;
}

@media (max-width: 480px) {
  #footer-bottom .footer-bottom-info > div .footer-bottom-menu ul {
    margin-right: 20px;
  }
}

#footer-bottom .footer-bottom-info > div .footer-bottom-menu ul li {
  font-family: 'MicrosoftYahei';
  color: #FFFFFF;
  margin-bottom: 5px;
  transition: all 0.3s linear;
}

#footer-bottom .footer-bottom-info > div .footer-bottom-menu ul li:hover {
  color: #84B736;
}

#footer-bottom .footer-bottom-info > div .footer-bottom-menu ul li a {
  font-family: 'MicrosoftYahei';
  color: #FFFFFF;
  transition: all 0.3s linear;
}

#footer-bottom .footer-bottom-info > div .footer-bottom-menu ul li a:hover {
  color: #84B736;
}

@media (max-width: 480px) {
  #footer-bottom .footer-bottom-info > div .footer-bottom-socialmedia {
    width: 100%;
    margin-top: 25px;
  }
}

#footer-bottom .footer-bottom-info > div .footer-bottom-socialmedia .social-icon-title {
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

#footer-bottom .footer-bottom-copyright {
  color: #747474;
  text-align: center;
  padding: 10px 0;
}

#footer-bottom .footer-bottom-copyright a {
  color: #747474;
  transition: all 0.3s linear;
}

#footer-bottom .footer-bottom-copyright a:hover {
  color: #FFFFFF;
}

#footer-bottom #backToTop {
  background: #84B736;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 52px;
  width: 42px;
  border-radius: 50px 50px 0 0;
  padding: 14px;
  cursor: pointer;
}

#footer-bottom #backToTop i {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
}

#frmContact {
  max-width: 800px;
  margin: 0 auto;
  background: #F1F1F1;
  padding: 20px;
}

#frmContact .contact-title {
  font-family: 'MicrosoftYahei';
  font-size: 16px;
  margin-bottom: 10px;
}

#frmContact .form-group .input-group {
  width: 100%;
}

#frmContact .form-group .input-group #enquiry {
  width: 100%;
}

#frmContact .contact-table {
  display: table;
  width: 100%;
}

#frmContact .contact-table .contact-table-row {
  display: table-row;
}

#frmContact .contact-table .contact-table-row .contact-table-label {
  display: table-cell;
  vertical-align: middle;
  font-family: 'MicrosoftYahei';
  font-size: 14px;
}

@media (max-width: 480px) {
  #frmContact .contact-table .contact-table-row .contact-table-label {
    display: block;
    margin-bottom: 10px;
  }
}

#frmContact .contact-table .contact-table-row .contact-table-input {
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 480px) {
  #frmContact .contact-table .contact-table-row .contact-table-input {
    display: block;
  }
}

#frmContact .contact-table .contact-table-row .contact-table-input .icheckbox_square-blue {
  margin-right: 10px;
  margin-left: 10px;
}

#frmContact .contact-table .contact-table-row .contact-table-input .icheckbox_square-blue:first-child {
  margin-left: 0;
}

#frmContact .contact-table .contact-table-row .contact-table-input .contact-input {
  margin-top: 10px;
}

#frmContact .contact-table .contact-table-row .contact-table-input .form-group {
  margin-bottom: 0;
  background: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

#frmContact .contact-table .contact-table-row .contact-table-input .form-group .form-control {
  background: transparent;
  border: none;
  box-shadow: none;
}

#frmContact .contact-corporate {
  margin: 30px 0 20px;
  padding: 15px;
  background: #FFFFFF;
}

#frmContact .contact-corporate .contact-corporate-title {
  font-family: 'MicrosoftYahei';
  font-size: 16px;
  margin-bottom: 25px;
}

#frmContact .contact-corporate .contact-corporate-table {
  display: table;
  width: 100%;
}

#frmContact .contact-corporate .contact-corporate-table .contact-corporate-row {
  display: table-row;
}

#frmContact .contact-corporate .contact-corporate-table .contact-corporate-row .contact-corporate-label {
  display: table-cell;
  vertical-align: middle;
  font-family: 'MicrosoftYahei';
  font-size: 14px;
}

@media (max-width: 480px) {
  #frmContact .contact-corporate .contact-corporate-table .contact-corporate-row .contact-corporate-label {
    display: block;
    margin-bottom: 10px;
  }
}

#frmContact .contact-corporate .contact-corporate-table .contact-corporate-row .contact-corporate-input {
  display: table-cell;
  vertical-align: middle;
  background: #F1F1F1;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

@media (max-width: 480px) {
  #frmContact .contact-corporate .contact-corporate-table .contact-corporate-row .contact-corporate-input {
    display: block;
  }
}

#frmContact .contact-corporate .contact-corporate-table .contact-corporate-row .contact-corporate-input .form-group {
  margin-bottom: 0;
}

#frmContact .contact-corporate .contact-corporate-table .contact-corporate-row .contact-corporate-input .form-group .form-control {
  background: transparent;
  border: none;
  box-shadow: none;
}

@media screen and (max-width: 325px) {
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.83);
    -webkit-transform: scale(0.83);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  #frmContact .g-recaptcha > div {
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  #news-listing .news .date {
    float: none !important;
    width: 100%;
    display: block;
  }
  #news-listing .news .link {
    height: auto !important;
    width: 100% !important;
  }
  #news-listing .news .link p {
    margin-bottom: 0 !important;
  }
}

.news_listing_list {
  margin-top: 40px;
}

@media (max-width: 480px) {
  .news_listing_list {
    margin-top: 15px !important;
  }
}

.news_listing_list .news_list {
  margin: 0 -25px;
  display: none;
  opacity: 0;
}

.news_listing_list .news_list .news_item {
  margin: 0 25px;
}

@media (max-width: 480px) {
  .news_listing_list .news_list .news_item {
    margin-bottom: 15px !important;
  }
}

.news_listing_list .news_list .news_item a {
  background: #F1F1F1;
  display: block;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0);
  transition: all 0.3s linear;
}

.news_listing_list .news_list .news_item a:hover {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}

.news_listing_list .news_list .news_item a > div .news_item_image {
  padding: 30%;
  background-size: cover;
  background-position: center center;
}

.news_listing_list .news_list .news_item a > div .news_item_image img {
  width: 100%;
}

.news_listing_list .news_list .news_item a > div .news_item_text {
  padding: 25px;
}

.news_listing_list .news_list .news_item a > div .news_item_text .news_item_name {
  color: #242424;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.news_listing_list .news_list .news_item a > div .news_item_text .news_item_description {
  color: #313131;
}

.news_listing_list .news_list .news_item a > div .news_item_content {
  padding: 0 25px 25px;
}

.news_listing_list .news_list .news_item a > div .news_item_content .news_item_date {
  color: #242424;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.news_listing_list .news_list .news_item a > div .news_item_content .news_item_date i {
  color: #84B736;
  width: 15px;
}

.tblSearches h5 {
  margin: 10px 0;
}

/*# sourceMappingURL=app.css.map */
